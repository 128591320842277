.app {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgb(243, 243, 250);

    .switch-btn {
        display: flex;
        flex-direction: row;
        align-items: center;
        background-color: #E8E8E8;
        width: 50px;
        height: 28px;
        border-radius: 30px;
        cursor: pointer;
    
        .switch {
            background-color: white;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            margin: 0 4px;
            cursor: pointer;
            transition: transform 0.5s;
        }
    }
    
    .active-switch {
        background-color: rgb(120, 112, 242);
    
        .switch {
            transform: translate(21px);
        }
    }

    .uploader {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 50vw;
        height: fit-content;
        margin-top: 100px;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.14);
        padding: 40px;
        padding-bottom: 30px;
        border-radius: 25px;
        background-color: white;

        .send-email {
            display: flex;
            flex-direction: row;
            align-items: center;
            align-self: flex-start;
            margin-top: 20px;

            label {
                margin-right: 15px;
                font-size: 18px;
            }
        }

        .upload-input {
            display: none;
        }

        .checked {
            width: 60px;
            height: 60px;
            margin-bottom: 40px;
        }

        .success-text {
            font-size: 30px;
            font-weight: 700;

            span {
                color: rgb(120, 112, 242);
            }
        }

        .drag-and-drop {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            border: 2px dashed rgb(211, 207, 247);
            border-radius: 25px;
            padding-top: 40px;
            padding-bottom: 40px;
            height: 200px;
            width: 100%;

            img {
                width: 80px;
                height: 80px;
            }

            .texts {
                display: flex;
                flex-direction: column;
                align-items: center;
                    
                .title {
                    font-size: 30px;
                    font-weight: 700;
                }
    
                .subtitle {
                    font-size: 20px;
                    font-weight: 700;
                    color: rgb(130, 130, 130);
                    margin-top: 20px;
    
                    span {
                        text-decoration: underline;
                        color: rgb(120, 112, 242);
                        cursor: pointer;
                    }
                }
            }
        }

        .files {
            display: flex;
            flex-direction: column;
            width: 100%;

            .file {
                width: 100%;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                margin: 20px 40px;

                .cancel {
                    width: 20px;
                    height: 20px;
                    margin-right: 80px;
                    cursor: pointer;
                }

                .file-left {
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    img {
                        width: 50px;
                        height: 50px;
                    }

                    .info {
                        display: flex;
                        flex-direction: column;
                        margin-left: 20px;

                        .name {

                        }

                        .file-infos {
                            display: flex;
                            flex-direction: row;

                            .file-info {
                                margin-right: 10px;
                            }
                        }
                    }
                }
            }
        }

        button {
            background-color:  rgb(120, 112, 242);
            border-radius: 18px;
            border: none;
            outline: none;
            color: white;
            width: 120px;
            height: 35px;
            margin-top: 30px;
            font-size: 15px;
            cursor: pointer;
        }

        input {
            width: 200px;
            outline: none;
            border-color:  rgb(120, 112, 242);
            border-radius: 18px;
            padding: 5px 10px;
            margin-top: 10px;
        }
    }
}


